
// import { initializeApp } from "firebase/app";


// import { getStorage } from "firebase/storage";

// // Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyAHG6ZqUgO5w4zlG9JodSt84yRrRuy-Ol0",
//     authDomain: "headsupb2b-v2.firebaseapp.com",
//     projectId: "headsupb2b-v2",
//     storageBucket: "headsupb2b-v2.appspot.com",
//     messagingSenderId: "982914188729",
//     appId: "1:982914188729:web:f93b2486b41193f0626268"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// export const storage = getStorage(app);

import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyAHG6ZqUgO5w4zlG9JodSt84yRrRuy-Ol0",
    authDomain: "headsupb2b-v2.firebaseapp.com",
    projectId: "headsupb2b-v2",
    storageBucket: "headsupb2b-v2.appspot.com",
    messagingSenderId: "982914188729",
    appId: "1:982914188729:web:f93b2486b41193f0626268"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}
var storage = firebase.storage();
export default storage;