import React, { useState, useEffect } from 'react';
import { deleteCategoryByID } from '../../Constant/Api/Api';
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button } from '@mantine/core';

function DeleteCategory({ category, onClose }) {
    const [opened, { open, close }] = useDisclosure(true);
    const [categoryID, setCategoryID] = useState('');

    useEffect(() => {
        if (category) {
            setCategoryID(category._id || '');
        }
    }, [category]);

    const handleDelete = async () => {
        try {
            const response = await fetch(`${deleteCategoryByID}${categoryID}`, {
                method: 'post',
            });

            if (response.ok) {
                console.log('Category deleted successfully.');
                onClose(); // Close the modal on successful deletion
            } else {
                console.error('Failed to delete category.');
            }
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    return (
        <div>
            <Modal opened={opened} onClose={onClose} title="Delete Category">
                <h1 className='bg-slate-100 font-semibold'>Are you sure you want to delete this category? </h1>
                <p>Category name: {categoryID}</p>
                <p>Category name: {category.name}</p>
                <div className="mt-4 flex justify-end space-x-4">
                    <Button onClick={onClose}>Cancel</Button>
                    <Button color="red" onClick={handleDelete}>Delete</Button>
                </div>
            </Modal>
        </div>
    );
}

export default DeleteCategory;
