import React, { useState, useEffect } from 'react';
import { Select } from '@mantine/core'; // Import Mantine's Select component
import { getAllSubCategory, getAllByCategory } from '../../Constant/Api/Api'; // Adjust the import as needed
import { FaRegEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import EditSubCategory from './EditSubCategory'; // Create this component
import DeleteSubCategoryModal from './DeleteSubCategory'; // Create this component
import CreateSubCategory from './CreateSubCategory';
import { HoverCard, Button, Text, Group } from '@mantine/core';
import { CiImageOn } from 'react-icons/ci';
import BrandImages from './BrandImages/BrandImages';
function ShowSubCategories() {
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [categoryData, setCategoryData] = useState([]); // State for categories

    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null); // State for selected category
    const [BrandImagesModal, SetBrandImagesModal] = useState(null);

    console.log("selectedsubCategory", selectedSubCategory);
    useEffect(() => {
        fetchSubCategoryData();
        fetchCategoryData();
    }, []);

    const fetchSubCategoryData = async () => {
        try {
            const response = await fetch(getAllSubCategory); // Update with the correct API endpoint for subcategories

            if (!response.ok) {
                throw new Error('Failed to fetch subcategory data');
            }

            const data = await response.json();
            setSubCategoryData(data);
        } catch (error) {
            console.error('Error fetching subcategory data:', error);
        }
    };

    const fetchCategoryData = async () => {
        try {
            const response = await fetch(getAllByCategory); // Update with the correct API endpoint for categories

            if (!response.ok) {
                throw new Error('Failed to fetch category data');
            }

            const data = await response.json();
            setCategoryData(data);
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    };

    const openEditModal = (subCategory) => {
        setSelectedSubCategory(subCategory);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedSubCategory(null);
        setEditModalOpen(false);
        fetchSubCategoryData();
    };

    const openDeleteModal = (subCategory) => {
        setSelectedSubCategory(subCategory);
        setDeleteModalOpen(true);
    };
    const openBrandImagesModal = (category) => {
        setSelectedSubCategory(category);
        SetBrandImagesModal(true);
    };

    const closeBrandImagesModal = () => {
        setSelectedSubCategory(null);
        SetBrandImagesModal(false);
        fetchSubCategoryData();
    };
    const closeDeleteModal = () => {
        setSelectedSubCategory(null);
        setDeleteModalOpen(false);
        fetchSubCategoryData();
    };

    const filteredSubCategories = selectedCategory
        ? subCategoryData.filter(subCategory => subCategory.category?._id === selectedCategory)
        : subCategoryData;

    return (
        <div>
            <h1 className="text-2xl font-bold mb-4 text-center">ShowSubCategories</h1>
            <div className='flex justify-between mx-12 mb-8'>
                <Select
                    placeholder="Select a category"
                    data={categoryData.map(category => ({ value: category._id, label: category.name }))}
                    value={selectedCategory}
                    onChange={setSelectedCategory}
                    clearable
                />
                <CreateSubCategory fetchSubCategoryData={fetchSubCategoryData} />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4 gap-4">
                {filteredSubCategories.map((subCategory) => (
                    <div key={subCategory._id} className="bg-white rounded-lg ovrflow-hidden shadow-xl">
                        <img src={subCategory.subCategoryImage} alt={subCategory.name} className="w-full h-48 object-scale-down" />
                        <div className="p-4">
                            <div className="flex flex-row justify-end text-2xl space-x-2 cursor-pointer">
                                <div onClick={() => openBrandImagesModal(subCategory)}>
                                    <CiImageOn className="text-green-700 cursor-pointer" />
                                </div>
                                <div onClick={() => openEditModal(subCategory)}>
                                    <FaRegEdit />
                                </div>
                                <div onClick={() => openDeleteModal(subCategory)}>
                                    <MdDelete className="text-red-700 cursor-pointer" />
                                </div>
                            </div>
                            <h2 className="text-xl font-bold mb-2">Name: <span className='font-normal'>{subCategory.name}</span> </h2>
                            <Group justify="">
                                <HoverCard width={280} shadow="md">
                                    <HoverCard.Target>
                                        <Button variant="filled" size="xs"><Text size="sm" fw={700}> Hover To read description</Text></Button>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown>
                                        <Text size="sm">
                                            {subCategory.description}
                                        </Text>

                                    </HoverCard.Dropdown>
                                </HoverCard>
                            </Group>
                            <p className="text-gray-700 font-bold">Category: <span className='font-normal'>{subCategory?.category?.name || "Category might have been deleted"}</span> </p>
                            <p className="text-gray-700 font-bold">slug: <span className='font-normal'>{subCategory?.slug}</span> </p>
                            <p className="text-gray-700 font-bold">Tags: {subCategory.tags.map((ele, i) => {
                                // if (category.tags.length === 1) {
                                //     return ele;
                                // }
                                return <span className='font-normal'>{ele + ', '}</span>
                            }
                            )}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            {editModalOpen && <EditSubCategory subCategory={selectedSubCategory} onClose={closeEditModal} />}
            {deleteModalOpen && <DeleteSubCategoryModal subCategory={selectedSubCategory} onClose={closeDeleteModal} />}
            {BrandImagesModal && <BrandImages subCategory={selectedSubCategory} onClose={closeBrandImagesModal} />}
        </div>
    );
}

export default ShowSubCategories;
