import React, { useState, useEffect } from 'react';
import { getAllByCategory } from '../../Constant/Api/Api';
import { FaRegEdit, FaQuestionCircle } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { CiImageOn } from "react-icons/ci";

import EditCategory from './EditCategory';
import DeleteCategoryModal from './DeleteCategoryModal';
import CreateCategory from './CreateCategory';
import CategoryFAQModal from './FAQ/CategoryFAQModal';
import { HoverCard, Button, Text, Group } from '@mantine/core';
import BrnadImages from './BrandImages/BrandImages';

function ShowCategories() {
    const [categoryData, setCategoryData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [editModalopen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [faqModalOpen, setFaqModalOpen] = useState(false);
    const [brandImagesModalOpen, setBrandImagesModalOpen] = useState(false);
    useEffect(() => {
        fetchCategoryData();
    }, []);

    const fetchCategoryData = async () => {
        try {
            const response = await fetch(getAllByCategory);

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setCategoryData(data);
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    };

    const openEditModal = (category) => {
        setSelectedCategory(category);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedCategory(null);
        setEditModalOpen(false);
        fetchCategoryData();
    };

    const openDeleteModal = (category) => {
        setSelectedCategory(category);
        setDeleteModalOpen(true);

    };

    const openCategoryFaqModal = (category) => {
        setSelectedCategory(category);
        setFaqModalOpen(true);
    };
    const openBrandImagesModal = (category) => {
        setSelectedCategory(category);
        setBrandImagesModalOpen(true);
    };

    const closeCategoryFaqModal = () => {
        setSelectedCategory(null);
        setFaqModalOpen(false); // Close the delete modal when it's closed
    };
    const closeBrandImagesModal = () => {
        setSelectedCategory(null);
        setBrandImagesModalOpen(false);
        fetchCategoryData(); // Close the delete modal when it's closed
    };

    const closeDeleteModal = () => {
        setSelectedCategory(null);
        setDeleteModalOpen(false); // Close the delete modal when it's closed
        fetchCategoryData();
    };
    // console.log("categoryData", categoryData);
    // console.log("faqModalOpen", faqModalOpen);
    return (
        <div>

            <h1 className="text-2xl font-bold mb-4 text-center">Show Categories</h1>
            <div className='flex justify-end mx-12 mb-8'>
                <CreateCategory fetchCategoryData={fetchCategoryData} />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4 gap-4">

                {categoryData.map((category) => (
                    <div key={category._id} className="bg-white rounded-lg overflow-hidden shadow-xl">
                        <img src={category.categoryImage} alt={category.name} className="w-full h-48 object-scale-down" />
                        <div className="p-4">
                            <div className="flex flex-row justify-end text-2xl space-x-2 cursor-pointer">
                                <div onClick={() => openCategoryFaqModal(category)}>
                                    <FaQuestionCircle className="text-green-700 cursor-pointer" />
                                </div>
                                <div onClick={() => openBrandImagesModal(category)}>
                                    <CiImageOn className="text-green-700 cursor-pointer" />
                                </div>
                                <div onClick={() => openEditModal(category)}>
                                    <FaRegEdit />
                                </div>
                                <div onClick={() => openDeleteModal(category)}>
                                    <MdDelete className="text-red-700 cursor-pointer" />
                                </div>

                            </div>

                            <h2 className="text-xl font-bold mb-2">Name: <span className='font-normal'>{category.name}</span> </h2>

                            <Group justify="">
                                <HoverCard width={280} shadow="md">
                                    <HoverCard.Target>
                                        <Button variant="filled" size="xs"><Text size="sm" fw={700}> Hover To read description</Text></Button>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown>
                                        <Text size="sm">
                                            {category.description}
                                        </Text>
                                    </HoverCard.Dropdown>
                                </HoverCard>
                            </Group>
                            <p className="text-gray-700 font-bold">Slug: <span className='font-normal'>{category.slug}</span> </p>
                            {/* </p> */}
                            <p className="text-gray-700 font-bold">Show On Header: <span className='font-normal'>{String(category.showOnHeader)}</span> </p>
                            <p className="text-gray-700 font-bold">Tags: {category.tags.map((ele, i) => {
                                // if (category.tags.length === 1) {
                                //     return ele;
                                // }
                                return <span className='font-normal'>{ele + ', '}</span>
                            }
                            )}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            {editModalopen && <EditCategory category={selectedCategory} onClose={closeEditModal} />}
            {deleteModalOpen && <DeleteCategoryModal category={selectedCategory} onClose={closeDeleteModal} />}
            {faqModalOpen && <CategoryFAQModal category={selectedCategory} onClose={closeCategoryFaqModal} />}
            {brandImagesModalOpen && <BrnadImages category={selectedCategory} onClose={closeBrandImagesModal} />}

        </div>
    );
}

export default ShowCategories;
