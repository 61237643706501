// const baseUrl = 'http://localhost:3002/api';

const baseUrl = 'https://api.headsupb2b.com/api';

const createCategory = `${baseUrl}/createCategory`;
const getAllByCategory = `${baseUrl}/getAllByCategory`;
const getCategoryByID = `${baseUrl}/getCategoryById`;
const updateCategoryByID = `${baseUrl}/updateCategory/`;
const deleteCategoryByID = `${baseUrl}/deleteCategory/`;
const addFaq = `${baseUrl}/addFaq`;
const updateFaq = `${baseUrl}/updateFaq`;
const deleteFaq = `${baseUrl}/deleteFaq`;
const addBrandImages = `${baseUrl}/addBrandImage`;
const updateBrandImages = `${baseUrl}/updateBrandImages`;
const deleteBrandImages = `${baseUrl}/deleteBrandImage`;



const careteSubCategory = `${baseUrl}/createSubCategory`;
const getAllSubCategory = `${baseUrl}/getAllSubCategories`;
const getSubCategoryByID = `${baseUrl}/getSubCategoryById/`;
const updateSubCategoryByID = `${baseUrl}/updateSubCategory/`;
const deleteSubCategoryByID = `${baseUrl}/deleteSubCategory/`;

const updateBrandImagesSC = `${baseUrl}/updateBrandImagesSC/`;

const createProduct = `${baseUrl}/createProduct`;
const getAllProducts = `${baseUrl}/getAllProducts`;
const getProductByID = `${baseUrl}/getProductById/`;
const updateProductByID = `${baseUrl}/updateProduct/`;
const deleteProductByID = `${baseUrl}/deleteProduct/`;
const updateBrandImagesProducts = `${baseUrl}/updateBrandImagesProducts/`;


export {
    createCategory,
    getAllByCategory,
    getCategoryByID,
    updateCategoryByID,
    deleteCategoryByID,
    addFaq,
    updateFaq,
    deleteFaq,
    addBrandImages,
    updateBrandImages,
    deleteBrandImages,

    careteSubCategory,
    getAllSubCategory,
    getSubCategoryByID,
    updateSubCategoryByID,
    deleteSubCategoryByID,
    updateBrandImagesSC,

    createProduct,
    getAllProducts,
    getProductByID,
    updateProductByID,
    deleteProductByID,
    updateBrandImagesProducts
};


