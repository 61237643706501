import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux'
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import '@mantine/core/styles.css';
import { createTheme, MantineProvider } from '@mantine/core';

import SampleReducer from './components/Store/Reducer/Auth/Sample/SampleReducer';

const rootReducer = combineReducers({
  SampleReducer: SampleReducer
})
const theme = createTheme({
  /** Put your mantine theme override here */
});
const store = createStore(rootReducer, applyMiddleware(thunk))

const root = createRoot(document.getElementById("root"));



root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <MantineProvider theme={theme}>
          <App />
        </MantineProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
