import React, { useState } from 'react';
import { createCategory } from '../../Constant/Api/Api';
import { Checkbox, TagsInput, Textarea, Progress, Modal, TextInput, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import uploadFile from '../../firebase/firebaseUploadFunction';

function CreateCategory({ fetchCategoryData }) {
    const [opened, { open, close }] = useDisclosure(false);
    const [categoryName, setCategoryName] = useState('');
    const [name2, setName2] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [secondDescription, setSecondDescription] = useState('');
    const [categoryImage, setCategoryImage] = useState(null);
    const [slug, setSlug] = useState('');
    const [imageUploadingLoader, setImageUploadingLoader] = useState(false);
    const [showOnHeader, setShowOnHeader] = useState(false);
    const [tags, setTags] = useState([]);

    const categoryNameToSlug = (name) => {
        const slugName = name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        setSlug(slugName);
    };

    const handleCategoryChange = (event) => {
        setCategoryName(event.target.value);
        categoryNameToSlug(event.target.value);
    };

    const handleName2Change = (event) => {
        setName2(event.target.value);
    };

    const handleSlugChange = (event) => {
        setSlug(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setCategoryDescription(event.target.value);
    };

    const handleSecondDescriptionChange = (event) => {
        setSecondDescription(event.target.value);
    };

    const handleImageChange = (e) => {
        setImageUploadingLoader(true);

        uploadFile(e)
            .then((fireBaseUrl) => {
                setCategoryImage(fireBaseUrl);
                setImageUploadingLoader(false);
            })
            .catch((error) => {
                setImageUploadingLoader(false);
                setCategoryImage(null);
                console.error('Error:', error);
            });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const formData = {
                name: categoryName,
                name2: name2,
                slug: slug,
                description: categoryDescription,
                secondDescription: secondDescription,
                categoryImage: categoryImage,
                showOnHeader: showOnHeader,
                tags: tags
            };

            const response = await fetch(createCategory, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();
            console.log('Success:', data);
            if (data) {
                close();
                setCategoryName('');
                setName2('');
                setCategoryDescription('');
                setSecondDescription('');
                setCategoryImage(null);
                setShowOnHeader(false);
                setTags([]);
                fetchCategoryData();
            }
        } catch (error) {
            console.error('Error creating category:', error);
        }
    };

    return (
        <div>
            <div>
                <Button onClick={open}>Create Category</Button>
                <Modal opened={opened} onClose={close} size="auto" title="Create Category">
                    <form onSubmit={handleSubmit} className='space-y-2'>
                        {imageUploadingLoader ? (
                            <>
                                <span className='bg-red-50'>please wait while image is uploading... </span>
                                <Progress radius="xl" value={100} striped animated />
                            </>
                        ) : (
                            categoryImage && <img src={categoryImage} alt="Category" className='w-full h-36 object-cover' />
                        )}
                        <TextInput
                            label="Category Name"
                            placeholder="Enter category name"
                            value={categoryName}
                            onChange={handleCategoryChange}
                            required
                        />
                        <TextInput
                            label="Second Title"
                            placeholder="Enter second name"
                            value={name2}
                            onChange={handleName2Change}
                            required
                        />
                        <TextInput
                            label="Slug"
                            placeholder="Enter category Slug (eg: road-safety-equipment)"
                            value={slug}
                            onChange={handleSlugChange}
                            required
                        />
                        <Textarea
                            label="Description"
                            placeholder="Enter category description"
                            value={categoryDescription}
                            onChange={handleDescriptionChange}
                            required
                        />
                        <Textarea
                            label="Second Description"
                            placeholder="Enter second description"
                            value={secondDescription}
                            onChange={handleSecondDescriptionChange}
                            required
                        />
                        <input
                            label="Category Image"
                            type='file'
                            accept='image/*'
                            placeholder="Select image"
                            onChange={(e) => { handleImageChange(e) }}
                            required
                        />
                        <TagsInput
                            label="Press Enter to submit a tag"
                            placeholder="Enter tag"
                            value={tags}
                            onChange={setTags}
                        />
                        <Checkbox
                            label="Show on header"
                            checked={showOnHeader}
                            onChange={(e) => setShowOnHeader(e.currentTarget.checked)}
                        />

                        <Button type="submit">Create</Button>
                    </form>
                </Modal>
            </div>
        </div>
    );
}

export default CreateCategory;
