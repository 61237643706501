import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminLayout from '../components/AdminLayout'; // Import the AdminLayout component
import Home from '../components/Home';
import Category from '../components/category/ShowCategories';
import ShowSubCategories from '../components/subCategory/ShowSubCategories';
import Product from '../components/Product';
import ShowProducts from '../components/product/ShowProducts';

export default function CustomRoutes() {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    // Wrap your SampleFiles component with AdminLayout
                    <AdminLayout>
                        <Home />
                    </AdminLayout>
                }
            />

            <Route
                path="/category"
                element={
                    // Wrap your Category component with AdminLayout
                    <AdminLayout>
                        <Category />
                    </AdminLayout>
                }
            />

            <Route
                path="/subCategory"
                element={
                    // Wrap your SubCategory component with AdminLayout
                    <AdminLayout>
                        <ShowSubCategories />
                    </AdminLayout>
                }
            />

            <Route
                path="/product"
                element={
                    // Wrap your Product component with AdminLayout
                    <AdminLayout>
                        <ShowProducts />
                    </AdminLayout>
                }
            />
        </Routes>
    );
}
