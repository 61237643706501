import React, { useState, useEffect } from 'react';
import { careteSubCategory, getAllByCategory } from '../../Constant/Api/Api'; // Adjust the imports as needed
import { useDisclosure } from '@mantine/hooks';
import { Modal, TextInput, FileInput, Button, Select, TagsInput } from '@mantine/core';
import uploadFile from '../../firebase/firebaseUploadFunction';

// Utility function to create slug
const generateSlug = (name) => {
    return name
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
        .replace(/(^-|-$)+/g, ''); // Remove leading and trailing hyphens
};

function CreateSubCategory({ fetchSubCategoryData }) {
    const [opened, { open, close }] = useDisclosure(false);
    const [categoryName, setCategoryName] = useState('');
    const [subCategoryName, setSubCategoryName] = useState('');
    const [subCategoryDescription, setSubCategoryDescription] = useState('');
    const [subCategoryImage, setSubCategoryImage] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState([]);
    const [slug, setSlug] = useState('');

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        setSlug(generateSlug(subCategoryName));
    }, [subCategoryName]);

    const fetchCategories = async () => {
        try {
            const response = await fetch(getAllByCategory);
            if (response.ok) {
                const data = await response.json();
                setCategories(data);
                setSelectedCategory(data.length > 0 ? data[0]._id : ''); // Set default selected category
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleSubCategoryChange = (event) => {
        setSubCategoryName(event.target.value);
    };

    const handleSubCategoryDescriptionChange = (event) => {
        setSubCategoryDescription(event.target.value);
    };

    const handleSubCategoryImageChange = (e) => {
        uploadFile(e)
            .then((fireBaseUrl) => {
                setSubCategoryImage(fireBaseUrl);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const formData = {
                name: subCategoryName,
                description: subCategoryDescription,
                subCategoryImage: subCategoryImage,
                category: selectedCategory,
                tags: tags,
                slug: slug,
            };

            const response = await fetch(careteSubCategory, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            console.log('Success:', data);

            if (data) {
                close();
                setSubCategoryName('');
                setSubCategoryDescription('');
                setSubCategoryImage(null);
                setTags([]);
                setSlug('');
                fetchSubCategoryData();
            }
        } catch (error) {
            console.error('Error creating subcategory:', error);
        }
    };

    return (
        <div>
            <div>
                <Button onClick={open}>Create SubCategory</Button>
                <Modal opened={opened} onClose={close} title="Create SubCategory">
                    <form onSubmit={handleSubmit}>
                        {subCategoryImage && <img src={subCategoryImage} alt="SubCategory image" />}
                        <TextInput
                            label="SubCategory Name"
                            placeholder="Enter subcategory name"
                            value={subCategoryName}
                            onChange={handleSubCategoryChange}
                            required
                        />
                        <TextInput
                            label="Description"
                            placeholder="Enter subcategory description"
                            value={subCategoryDescription}
                            onChange={handleSubCategoryDescriptionChange}
                            required
                        />
                        <TextInput
                            label="Slug"
                            placeholder="Slug"
                            value={slug}
                            readOnly
                        />
                        <Select
                            label="Category"
                            data={categories.map((category) => ({ label: category.name, value: category._id }))}
                            value={selectedCategory}
                            onChange={(value) => handleCategoryChange(value)}
                        />
                        <input
                            label="SubCategory Image"
                            type='file'
                            accept='image/*'
                            placeholder="Select image"
                            onChange={(e) => handleSubCategoryImageChange(e)}
                            required
                        />
                        <TagsInput
                            label="Press Enter to submit a tag"
                            placeholder="Enter tag"
                            value={tags}
                            onChange={setTags}
                        />
                        <Button type="submit">Create</Button>
                    </form>
                </Modal>
            </div>
        </div>
    );
}

export default CreateSubCategory;
