import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Link, navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
export default function Home() {
    const dispatch = useDispatch();
    const reduxData = useSelector(state => state.SampleReducer);
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/category");
    }, [navigate]);

    return (
        <>
            <p>Home</p>
        </>
    );
}
