import React, { useState } from 'react';
import { Button, Progress, Modal } from '@mantine/core';
import uploadFile from '../../../firebase/firebaseUploadFunction';
import { updateBrandImagesProducts } from '../../../Constant/Api/Api';

const BrandImages = ({ product, onClose }) => {
    const [imageUploadingLoader, setImageUploadingLoader] = useState(false);
    const [opened, setOpened] = useState(true);
    const [productImage, setproductImage] = useState(product?.brandImagesProducts);
    const id = product._id;
    const updateCategoryImages = async (updatedImages) => {

        try {
            const response = await fetch(`${updateBrandImagesProducts}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id, brandImagesProducts: updatedImages }),
            });

            if (!response.ok) {
                throw new Error('Failed to update category images');
            }

            const updatedCategory = await response.json();
            setproductImage(updatedCategory.brandImagesProducts);
            console.log("updatedCategory----", updatedCategory);
        } catch (error) {
            console.error('Error updating category images:', error);
        }
    };

    const handleImageChange = (e) => {

        setImageUploadingLoader(true);
        uploadFile(e)
            .then((fireBaseUrl) => {
                const updatedImages = [...productImage, fireBaseUrl];
                updateCategoryImages(updatedImages);
                console.log("updatedImages", updatedImages);
                setImageUploadingLoader(false);
            })
            .catch((error) => {
                setImageUploadingLoader(false);
                console.error('Error:', error);
            });
    };

    const handleDeleteImage = (imageUrl) => {
        const updatedImages = productImage.filter((image) => image !== imageUrl);
        updateCategoryImages(updatedImages);
    };

    console.log("productImage---", product.brandImagesSC);

    return (
        <div>
            <Modal opened={opened} onClose={() => { setOpened(false); onClose(); }} size="auto" title="Brand Image">
                <form className='space-y-2'>
                    {imageUploadingLoader ? (
                        <>
                            <span className='bg-red-50'>Please wait while the image is uploading...</span>
                            <Progress radius="xl" value={100} striped animated />
                        </>
                    ) : (
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            disabled={imageUploadingLoader}
                        />
                    )}
                </form>

                <div className="space-y-2">
                    {productImage?.map((image, index) => (
                        <div key={index} className="relative">
                            <img src={image} alt="Brand" className="w-full h-36 object-cover" />
                            <Button
                                className="absolute top-2 right-2"
                                color="red"
                                onClick={() => handleDeleteImage(image)}
                            >
                                Delete
                            </Button>
                        </div>
                    ))}
                </div>
            </Modal>
        </div>
    );
};

export default BrandImages;
