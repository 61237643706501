import React, { useState, useEffect } from 'react';
import { updateCategoryByID } from '../../Constant/Api/Api';
import { TagsInput, Textarea, Checkbox, Progress, Modal, TextInput, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import uploadFile from '../../firebase/firebaseUploadFunction';

function EditCategory({ category, onClose }) {
    const [opened, { open, close }] = useDisclosure(true);
    const [categoryID, setCategoryID] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [name2, setName2] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [secondDescription, setSecondDescription] = useState('');
    const [categoryImage, setCategoryImage] = useState(null);
    const [slug, setSlug] = useState(category.slug || '');
    const [imageUploadingLoader, setImageUploadingLoader] = useState(false);
    const [showOnHeader, setShowOnHeader] = useState(category.showOnHeader || false);
    const [tags, setTags] = useState(category.tags || []);

    useEffect(() => {
        if (category) {
            setCategoryID(category._id || '');
            setCategoryName(category.name || '');
            setName2(category.name2 || '');
            setCategoryDescription(category.description || '');
            setSecondDescription(category.secondDescription || '');
            setCategoryImage(category.categoryImage || null);
            setSlug(category.slug || '');
        }
    }, [category]);

    const handleCategoryChange = (event) => {
        setCategoryName(event.target.value);
    };

    const handleName2Change = (event) => {
        setName2(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setCategoryDescription(event.target.value);
    };

    const handleSecondDescriptionChange = (event) => {
        setSecondDescription(event.target.value);
    };

    const handleSlugChange = (event) => {
        setSlug(event.target.value);
    };

    const handleImageChange = (e) => {
        setImageUploadingLoader(true);
        uploadFile(e)
            .then((fireBaseUrl) => {
                setCategoryImage(fireBaseUrl);
                setImageUploadingLoader(false);
            })
            .catch((error) => {
                setImageUploadingLoader(false);
                console.error('Error:', error);
            });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("submitted");

        try {
            const formData = {
                name: categoryName,
                name2: name2,
                description: categoryDescription,
                secondDescription: secondDescription,
                categoryImage: categoryImage,
                showOnHeader: showOnHeader,
                tags: tags,
                slug
            };

            const response = await fetch(`${updateCategoryByID}${categoryID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            console.log('Success:', formData);

            if (data) {
                onClose(); // Close the modal on successful submission
                setCategoryName('');
                setName2('');
                setCategoryDescription('');
                setSecondDescription('');
                setCategoryImage(null);
            }
        } catch (error) {
            console.error('Error creating category:', error);
        }
    };

    return (
        <div>
            <Modal opened={opened} onClose={onClose} size="auto" title="Update Category">
                <form onSubmit={handleSubmit} className='space-y-2'>
                    {imageUploadingLoader ? (
                        <>
                            <span className='bg-red-50'>please wait while image is uploading... </span>
                            <Progress radius="xl" value={100} striped animated />
                        </>
                    ) : (
                        categoryImage && <img src={categoryImage} alt="Category" className='w-full h-36 object-cover' />
                    )}
                    <TextInput
                        label="Category Name"
                        placeholder="Enter category name"
                        value={categoryName}
                        onChange={handleCategoryChange}
                    />
                    <TextInput
                        label="Second Name"
                        placeholder="Enter second name"
                        value={name2}
                        onChange={handleName2Change}
                    />
                    <TextInput
                        label="Slug"
                        placeholder="Enter category Slug (eg: road-safety-equipment)"
                        value={slug}
                        onChange={handleSlugChange}
                    />
                    <Textarea
                        label="Description"
                        placeholder="Enter category description"
                        value={categoryDescription}
                        onChange={handleDescriptionChange}
                    />
                    <Textarea
                        label="Second Description"
                        placeholder="Enter second description"
                        value={secondDescription}
                        onChange={handleSecondDescriptionChange}
                    />
                    <input
                        label="Category Image"
                        type="file"
                        accept='image/*'
                        placeholder="Select image"
                        onChange={(e) => {
                            handleImageChange(e);
                        }}
                    />
                    <TagsInput
                        label="Press Enter to submit a tag"
                        placeholder="Enter tag"
                        value={tags}
                        onChange={setTags}
                    />
                    <Checkbox
                        label="Show on header"
                        checked={showOnHeader}
                        onChange={(e) => setShowOnHeader(e.currentTarget.checked)}
                    />

                    <Button type="submit">Update</Button>
                </form>
            </Modal>
        </div>
    );
}

export default EditCategory;
