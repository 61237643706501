import React from 'react'
import Routes from './Routes';
import { Link } from 'react-router-dom';
export default function Navigation() {
  return (
    <div>

      <Routes />

    </div>
  )
}
