import storage from '../firebase/config';

const uploadFile = (e) => {
    return new Promise((resolve, reject) => {
        var folderName = `category/`;
        var file = e.target.files[0];
        var fileName = e.target.files[0].name;
        var Filetype = e.target.files[0].name;
        var name = Date.now() + fileName.split(".").pop() + Filetype;

        const uploadTask = storage.ref(`/category/${name}`).put(file);

        uploadTask.on(
            "state_changed",
            (snapShot) => { },
            (err) => {
                reject(err);  // Reject if there's an error
            },
            () => {
                storage
                    .ref(folderName)
                    .child(name.toString())
                    .getDownloadURL()
                    .then((fireBaseUrl) => {
                        if (fireBaseUrl !== undefined) {
                            console.log("fireBaseUrl", fireBaseUrl);
                            resolve(fireBaseUrl);  // Resolve with the URL
                        }
                    })
                    .catch((error) => {
                        reject(error);  // Reject if there's an error in getting the download URL
                    });
            }
        );
    });
};

export default uploadFile;
