import React, { useState, useEffect } from 'react';

import { useDisclosure } from '@mantine/hooks';
import { Modal, Button } from '@mantine/core';
import FaqForm from './FaqForm';

function CategoryFAQModal({ category, onClose }) {
    const [opened, { open, close }] = useDisclosure(true);
    const [categoryID, setCategoryID] = useState('');


    console.log("from CategoryFAQModal----", category);
    useEffect(() => {

    }, []);
    console.log("category---", category);

    return (
        <div>
            <Modal opened={opened} onClose={onClose} title="Manage FAQs">
                <FaqForm categoryId={category._id} />




            </Modal>
        </div>
    );
}

export default CategoryFAQModal;
