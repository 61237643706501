import React from 'react';
import { Link } from 'react-router-dom';

const AdminLayout = ({ children }) => {
    return (
        <div className="flex flex-col min-h-screen">
            <h1 className="text-center text-2xl font-semibold mb-4">Admin Panel</h1>
            <div className="flex flex-col md:flex-row flex-1">
                {/* Left Column */}
                <div className="md:w-1/4 bg-purple-50 p-4 md:min-h-screen">
                    <div className="mb-4">
                        <Link to="/category" className="text-lg font-semibold block hover:bg-purple-100 p-2 rounded-md">
                            Category
                        </Link>
                    </div>
                    <div className="mb-4">
                        <Link to="/subCategory" className="text-lg font-semibold block hover:bg-purple-100 p-2 rounded-md">
                            Subcategory
                        </Link>
                    </div>
                    <div>
                        <Link to="/product" className="text-lg font-semibold block hover:bg-purple-100 p-2 rounded-md">
                            Products
                        </Link>
                    </div>
                </div>

                {/* Main Content */}
                <div className="md:w-3/4 p-4 flex-1">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AdminLayout;
